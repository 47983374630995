.GroupCard {
  width: 320px;
  height: 100px;
  background-color: #fff;
  cursor: pointer;
  padding: 0;
  border: none;
  user-select: none;
}

.GroupCard>.GroupCardBox {
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  align-items: center;
  padding: 0 30px;
}

.GroupCard:focus {
  outline: none;
}

.GroupCard:hover {
  background-color: #e1f1f9;
}

.GroupCard>.GroupCardBox>.GroupCard__name {
  font-size: 20px;
  color: #7b797b;
  font-weight: 600;
  letter-spacing: 2px;
  padding: 0;
  border: none;
  outline: none;
  background: none;
  cursor: pointer;
  grid-column: 3/10;
  text-align: left;
  word-wrap: break-word;
}

.GroupCard>.GroupCardBox>.GroupCard__name:focus {
  outline: none;
}

.GroupCard>.GroupCardBox>.GroupCard__users {
  font-size: 20px;
  color: #148bd7;
  font-weight: 500;
  letter-spacing: 2px;
  grid-column: 10/11;
}