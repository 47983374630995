.UsersList {
  height: 100%;
}

.UsersList>.UsersList__table {
  margin-top: 25px;
  height: calc(100% - 63px);
}

.UsersList>.UsersList__Toolbar>.left>.gotoUser {
  display: flex;
}

.UsersList>.UsersList__Toolbar>.left>.gotoUser>.gotoUser-input {
  margin-right: 10px;
  width: auto;
}