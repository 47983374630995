.MessagesHome {
  max-height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.MessagesHome>.MessagesHome__Toolbar>.left>.TabPills>.nav>.nav-item>.nav-link {
  cursor: pointer;
}
