.DrEventUploadLogs {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.DrEventUploadLogs h3>b {
  user-select: text;
}

.DrEventUploadLogs>pre {
  height: 100%;
  width: 100%;
  max-height: none;
}

.DrEventUploadLogs>button {
  width: 100%;
  margin-bottom: 10px;
}

.DrEventUploadLogs>.DrEventUploadLogs__error {
  height: 50%;
  width: 50%;
  text-align: center;
}

.DrEventUploadLogs>.DrEventUploadLogs__error>button {
  width: 100%;
  margin-top: 10px;
}