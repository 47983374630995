.GroupsHome {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.GroupsHome__toolbar>.left>.search {
  display: flex;
}

.GroupsHome__toolbar>.left>.search>.search-input {
  margin-right: 10px;
  width: auto;
}

.GroupsHome__cards {
  display: flex;
  flex-wrap: wrap;
}

.GroupsHome__cards>* {
  margin: 25px;
}