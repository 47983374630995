.SelectBox {
  width: 25px;
  height: 25px;
  border-radius: 4px;
  background-color: #ffffff;
  border: solid 1px #d0d0d0;
  position: relative;
  cursor: pointer;
}

.SelectBox.selected:after {
  content: '';
  height: 15px;
  width: 15px;
  border-radius: 4px;
  background-color: #148bd7;
  position: absolute;
  top: 4px;
  left: 4px;
}
