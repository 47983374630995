.TopNavBar {
  width: calc(100% - 266px);
  height: 150px;
  position: fixed;
  right: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: width 0.5s ease-in-out;
  user-select: none;
}

.TopNavBar>.TopBar {
  height: 70px;
  width: 100%;
  background-color: #fff;
  padding: 13px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.TopNavBar>.TopBar>.TopNavBar__signOut {

}

.TopNavBar>.TopBar>.hamburger {
  width: 18px;
  height: 16px;
  background-image: url('../../assets/navbar/TopNavBar/Expand.png');
  background-color: transparent;
  border: none;
  display: inline-block;
  padding: 0;
  background-size: contain;
  transform: rotate(180deg);
  cursor: pointer;
}

.TopNavBar>.TopBar>.hamburger:active {
  filter: contrast(10%);
}

.TopNavBar>.TopBar>.hamburger:focus {
  outline: none;
}

.TopNavBar>.TopBar>.hamburger.collapsed {
  transform: none;
}

.TopNavBar>.PathBar {
  height: 80px;
  width: 100%;
  padding: 1%;
  display: flex;
  align-items: center;
  background-color: #f0f0f0;
}

.TopNavBar>.PathBar>.Path {
  display: flex;
  align-items: center;
  margin: 20px;
  font-size: 18px;
  letter-spacing: 1.3px;
  color: #4a4a4a;
  cursor: pointer;
  text-decoration: none;
}

.TopNavBar>.PathBar>.Path>.Path__icon {
  height: 24px;
  width: 24px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  margin-right: 16px;
}

.TopNavBar>.PathBar>.Path>.Path__name {
  text-transform: capitalize;
}

.TopNavBar>.PathBar>.Path>.Path__icon.groups {
  background-image: url('../../assets/navbar/SideNavBar/Users.png');
}

.TopNavBar>.PathBar>.Path>.Path__icon.messages {
  background-image: url('../../assets/navbar/SideNavBar/Posts.png');
}

.TopNavBar>.PathBar>.Path>.Path__icon.users {
  background-image: url('../../assets/navbar/SideNavBar/Group.png');
}

.TopNavBar>.PathBar>.Path>.Path__icon.dr {
  background-image: url('../../assets/navbar/SideNavBar/DR.png');
}

.TopNavBar.collapsed {
  width: calc(100% - 135px);
  transition: width 0.5s ease-in-out;
}
