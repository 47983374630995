.Loading {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
}

.Loading__icon--small {
  width: 150px;
  height: 150px;
}

.Loading__icon--medium {
  width: 300px;
  height: 300px;
}

.Loading__icon--large {
  width: 450px;
  height: 450px;
}
