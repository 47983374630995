.MessagesDetailed {
  
}

.MessagesDetailed>.MessageContent {
  
}

.MessagesDetailed table th {
  width: 200px;
}