.ExpandingSideNav {
  height: 60px;
  overflow: hidden;
}

.ExpandingSideNav.expanded {
  height: auto;
}

.ExpandingSideNav .ExpandingSideNav__item {
  height: 60px;
  color: #4a4a4a;
  display: grid;
  grid-template-columns: repeat(24, 1fr);
  align-items: center;
  position: relative;
  font-size: 18px;
  letter-spacing: 1.3px;
}

.ExpandingSideNav .ExpandingSideNav__item.active {
  background-color: #e1f1f9;
  color: #148bd7;
}

.ExpandingSideNav .ExpandingSideNav__item.active::after {
  content: "";
  height: 100%;
  width: 4px;
  background-color: #148bd7;
  position: absolute;
  right: 0px;
}

.ExpandingSideNav .ExpandingSideNav__item:not(.active):hover {
  background-color: #f4fbff;
}

.ExpandingSideNav .ExpandingSideNav__item:not(.active):hover:after {
  content: "";
  height: 100%;
  width: 4px;
  background-color: #8ab5d1;
  position: absolute;
  right: 0px;
}

.ExpandingSideNav .ExpandingSideNav__item:hover {
  text-decoration: none;
}

.ExpandingSideNav .ExpandingSideNav__item>.ExpandingSideNav__itemTitle {
  grid-column: 10 / 23;
}

.ExpandingSideNav>.ExpandingSideNav__mainItem>.ExpandingSideNav__itemTitle {
  grid-column: 9 / 22;
}

.ExpandingSideNav>.ExpandingSideNav__mainItem>.ExpandingSideNav__itemLogo {
  height: 24px;
  width: 24px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  grid-column: 5 / 7;
}

.ExpandingSideNav>.ExpandingSideNav__mainItem.active>.ExpandingSideNav__itemLogo {
  filter: invert(48%) sepia(40%) saturate(5665%) hue-rotate(179deg) brightness(85%) contrast(85%);
}

.ExpandingSideNav>.ExpandingSideNav__mainItem>.ExpandingSideNav__itemLogo.ExpandingSideNav__expander {
  grid-column: 22 / 24;
  transform: rotate(0deg);
  background-image: url('../../../assets/navbar/SideNavBar/down.png') !important;
  width: 12px;
  height: 7.4px;
}

.ExpandingSideNav>.ExpandingSideNav__mainItem>.ExpandingSideNav__itemLogo.ExpandingSideNav__expander:focus {
  outline: none;
}

.ExpandingSideNav.expanded>.ExpandingSideNav__mainItem>.ExpandingSideNav__itemLogo.ExpandingSideNav__expander {
  transform: rotate(180deg);
}

.ExpandingSideNav>.ExpandingSideNav__mainItem {

}

.ExpandingSideNav>.ExpandingSideNav__mainItem.active {

}

.ExpandingSideNav.collapsed {
  width: 135px;
  transition: width 0.5s ease-in-out;
}

.ExpandingSideNav.collapsed>.ExpandingSideNav__mainItem>.ExpandingSideNav__itemTitle {
  display: none;
}

.ExpandingSideNav.collapsed>.ExpandingSideNav__mainItem>.ExpandingSideNav__itemLogo {
  grid-column: 11 / 14;
}

.ExpandingSideNav.collapsed>.ExpandingSideNav__mainItem>.ExpandingSideNav__itemLogo.ExpandingSideNav__expander {
  display: none;
}