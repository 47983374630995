.GroupsDrEvents {
  height: 100%;
}

.GroupsDrEvents>.GroupsDrEvents__eventsTable {
  margin-top: 25px;
  background-color: #fff;
  margin-bottom: 0px;
}

.GroupsDrEvents>table.GroupsDrEvents__eventsTable>tbody>tr.event {
  cursor: pointer;
}

.GroupsDrEvents>table.GroupsDrEvents__eventsTable>tbody>tr:hover {
  background-color: #e1f1f9;
}