.Home {
  width: 100%;
  height: 100%;
  background-color: #f0f0f0;
  position: relative;
}

.Home>.Home-Content {
  position: absolute;
  background-color: #f0f0f0;
  padding: 1%;
  top: 150px;
  left: 266px;
  width: calc(100% - 266px);
  overflow: auto;
  height: calc(100vh - 150px);
  transition: left 0.5s ease-in-out, width 0.5s ease-in-out;
}

.Home.collapsed>.Home-Content {
  width: calc(100% - 135px);
  left: 135px;
  transition: all 0.5s ease-in-out;
}