.Button {
  background: none;
  border: none;
  cursor: pointer;
  min-height: 38px;
  padding-left: 14px;
  padding-right: 14px;
  user-select: none;
  position: relative;
  overflow: hidden;
  border-radius: 2px;
}

.Button:focus {
  outline: 0;
}

.Button>.button-content {
  z-index: 3;
  position: relative;
  font-size: 16px;
  letter-spacing: 2px;
}

.Button>.backgroundColor {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0px;
  left: 0px;
}

.Button:active>.backgroundColor {
  filter: contrast(75%);
}

.Button:disabled>.backgroundColor {
  filter: contrast(25%);
}

.Button.blue>.button-content {
  color: #ffffff;
}

.Button.blue>.backgroundColor {
  background-color: #1380d1;
}

.Button.primary>.button-content {
  color: #1380d1;
}

.Button.primary>.backgroundColor {
  background-color: #fff;
}

.Button.primary {
  border: 1px solid #1380d1;
}

.Button.primary:disabled>.backgroundColor {
  filter: none;
  background-color: #d0d0d0;
}

.Button.primary:disabled>.button-content {
  color: #fff;
}

.Button.primary:disabled {
  border: 1px solid #d0d0d0;
}

.Button.secondary>.button-content {
  color: #7b797b;
}

.Button.secondary>.backgroundColor {
  background-color: #fff;
}

.Button.secondary {
  border: 1px solid #7b797b;
}

.Button.secondary:disabled>.backgroundColor {
  filter: contrast(75%);
}

.Button.danger>.button-content {
  color: #ffffff;
}

.Button.danger>.backgroundColor {
  background-color: #ff5252;
}

