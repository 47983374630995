.GroupsList {
  width: 100%;
  overflow: visible;
  box-sizing: border-box;
  padding-bottom: 1px;
}

.GroupsList>li {
  height: 100px;
  display: flex;
  align-items: center;
  padding: 25px;
  user-select: none;
  cursor: pointer;
  border: solid 1px #e1e1e1;
  letter-spacing: 1.3px;
  color: #4a4a4a;
  font-size: 18px;
  position: relative;
}

.GroupsList>li:not(.selected):hover {
  background-color: #f4fbff;
}

.GroupsList>li>.id {
  position: absolute;
  font-size: 10px;
  right: 5px;
  bottom: 5px;
  color: #aaa;
}

.GroupsList>li.selected {
  background-color: #e1f1f9;
  cursor: default;
}