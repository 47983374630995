.MessagesTable {
  margin: 25px;
  background-color: #fff;
}

.MessagesTable>table {
  margin-bottom: 0;
}

.MessagesTable>table>tbody>tr.message {
  cursor: pointer;
}

.MessagesTable>table>tbody>tr:hover {
  background-color: #e1f1f9;
}