.Tabs {
  display: flex;
  height: 40px;
}

.Tabs>.tab {
  padding: 7px 35px;
  box-sizing: border-box;
  border-bottom: solid 2px #e1e1e1;
  color: #7b797b;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.5px;
  cursor: pointer;
  user-select: none;
}

.Tabs>.tab:focus {
  outline: none;
}

.Tabs>.tab.active {
  border-bottom: solid 2px #148bd7;
  color: #148bd7;
  cursor: default;
}