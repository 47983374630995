.SideNavBar {
  height: 100%;
  width: 266px;
  background-color: #fff;
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: width 0.5s ease-in-out;
  user-select: none;
}

.SideNavBar>.SideNavBar__title {
  width: 205px;
  height: 55px;
  text-align: center;
  margin: 30px;
  background-image: url('../../assets/navbar/SideNavBar/Enertalk.png');
  background-size: cover;
  background-position: center;
  transition: width 0.5s ease-in-out, background-position 0.5s ease-in-out;
}

.SideNavBar>.SideNavBar__items {
  display: flex;
  flex-direction: column;
}

.SideNavBar>.SideNavBar__items>.SideNavBar__item {
  height: 60px;
  color: #4a4a4a;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  align-items: center;
  position: relative;
  font-size: 18px;
  letter-spacing: 1.3px;
}

.SideNavBar>.SideNavBar__items>.SideNavBar__item>.SideNavBar__itemTitle {
  grid-column: 5 / 13;
  padding-right: 20px;
}

.SideNavBar>.SideNavBar__items>.SideNavBar__item>.SideNavBar__itemLogo {
  height: 24px;
  width: 24px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  grid-column: 3 / 4;
}

.SideNavBar>.SideNavBar__items>.SideNavBar__item.active>.SideNavBar__itemLogo {
  filter: invert(48%) sepia(40%) saturate(5665%) hue-rotate(179deg) brightness(85%) contrast(85%);
}

.SideNavBar>.SideNavBar__items>.SideNavBar__item>.SideNavBar__itemLogo.groups {
  background-image: url('../../assets/navbar/SideNavBar/Users.png');
}

.SideNavBar>.SideNavBar__items>.SideNavBar__item>.SideNavBar__itemLogo.users {
  background-image: url('../../assets/navbar/SideNavBar/Group.png');
}

.SideNavBar>.SideNavBar__items>.SideNavBar__item>.SideNavBar__itemLogo.messages {
  background-image: url('../../assets/navbar/SideNavBar/Posts.png');
}

.SideNavBar>.SideNavBar__items>.SideNavBar__item>.SideNavBar__itemLogo.dr {
  background-image: url('../../assets/navbar/SideNavBar/DR.png');
}

.SideNavBar>.SideNavBar__items>.ExpandingSideNav.dr>.ExpandingSideNav__mainItem>.ExpandingSideNav__itemLogo {
  background-image: url('../../assets/navbar/SideNavBar/DR.png');
}

.SideNavBar>.SideNavBar__items>.SideNavBar__item.active {
  background-color: #e1f1f9;
  color: #148bd7;
}

.SideNavBar>.SideNavBar__items>.SideNavBar__item:not(.active):hover {
  background-color: #f4fbff;
}

.SideNavBar>.SideNavBar__items>.SideNavBar__item:not(.active):hover:after {
  content: "";
  height: 100%;
  width: 4px;
  background-color: #8ab5d1;
  position: absolute;
  right: 0px;
}

.SideNavBar>.SideNavBar__items>.SideNavBar__item.active::after {
  content: "";
  height: 100%;
  width: 4px;
  background-color: #148bd7;
  position: absolute;
  right: 0px;
}

.SideNavBar>.SideNavBar__items>.SideNavBar__item:hover {
  text-decoration: none;
}

.SideNavBar.collapsed {
  width: 135px;
  transition: width 0.5s ease-in-out;
}

.SideNavBar.collapsed>.SideNavBar__title {
  width: 35px;
  margin: 30px 0;
  background-position: -9px;
}

.SideNavBar.collapsed>.SideNavBar__items>.SideNavBar__item>.SideNavBar__itemTitle {
  display: none;
}

.SideNavBar.collapsed>.SideNavBar__items>.SideNavBar__item>.SideNavBar__itemLogo {
  grid-column: 6 / 8;
}