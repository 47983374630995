.UsersTable {
  background-color: #fff;
}

.UsersTable>.pagination-bottom>.-pagination>.-center>.-pageSizeOptions {
  display: none;
}

.UsersTable>.rt-table>.rt-thead>.rt-tr>.rt-th {
  border-right: solid 1px #e1e1e1;
  border-bottom: solid 1px #e1e1e1;
}

.UsersTable>.rt-table>.rt-tbody>.rt-tr-group {
  border-bottom: solid 1px #e1e1e1;
}

.UsersTable>.rt-table>.rt-tbody>.rt-tr-group>.rt-tr:not(.-padRow) {
  cursor: pointer;
}

.UsersTable>.rt-table>.rt-tbody>.rt-tr-group>.rt-tr.-odd {
  background-color: #fff;
}

.UsersTable>.rt-table>.rt-tbody>.rt-tr-group>.rt-tr.-even {
  background-color: #eef0f5;
}

.UsersTable>.rt-table>.rt-tbody>.rt-tr-group>.rt-tr.-odd:not(.-padRow):hover,
.UsersTable>.rt-table>.rt-tbody>.rt-tr-group>.rt-tr.-even:not(.-padRow):hover {
  background-color: #e1f1f9;
}

.UsersTable>.rt-table>.rt-tbody>.rt-tr-group>.rt-tr>.rt-td {
  border-right: solid 1px #e1e1e1;
}

.UsersTable table {
  cursor: default;
}
