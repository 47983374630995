.GroupsSelectList {
  display: flex;
  flex-direction: column;
}

.GroupsSelectList>.Group {
  display: flex;
  margin-bottom: 5px;
  cursor: pointer;
}

.GroupsSelectList>.Group:focus {
  outline: none;
}

.GroupsSelectList>.Group:last-of-type {
  margin-bottom: 0;
}

.GroupsSelectList>.Group>.SelectBox {
  margin-right: 5px;
}

.GroupsSelectList>.Group>.id {
  margin-right: 5px;
}
