.DrEventsList {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-gap: 10px;
  grid-template-rows: repeat(3, 1fr);
  width: 100%;
  height: 100%;
  min-height: 0;
  min-width: 0;
}

.DrEventsList>.GroupsList {
  grid-column: 1 / 2;
  grid-row: 1 / 4;
  overflow-y: auto;
  overflow-x: hidden;
  width: 245px;
}

.DrEventsList>.DREventsList {
  grid-column: 2 / 8;
  grid-row: 1 / 4;
  min-width: 0;
  min-height: 0;
  overflow-y: auto;
  overflow-x: hidden;
}