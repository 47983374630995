.UserGroups {
  flex-basis: 49%;
}

.UserGroups>.header {
  margin-bottom: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.UserGroups>.header>h3 {
  display: inline;
}

.UserGroups>.GroupsTable .Button.RemoveGroup {
  min-height: auto;
  height: 20px;
  width: 20px;
  padding: 0;
}

.UserGroups>.GroupsTable .Button.RemoveGroup>.button-content {
  font-size: 14px;
  letter-spacing: 0;
  line-height: 0;
}