#root {
  min-height: 100vh;
  min-width: 100vw;
  display: flex;
  overflow: hidden;
}

html>body {
  font-family: 'Lato',  sans-serif;
}

table>thead {
  user-select: none;
}

h3 {
  user-select: none;
}
