.UserProfile {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
}

.UserProfile>* {
  flex-basis: 49%;
}

.UserProfile>.OnboardingInfo .EventList {
  max-height: 200px;
  overflow-y: scroll;
}

.UserProfile>.OnboardingInfo .EventList>table {
  margin-bottom: 0;
}
