.Authentication {
  display: flex;
  flex-direction: column;
  align-self: center;
  width: 100%;
  align-items: center;
}

.Authentication>.enertalk-logo {
  background-image: url('../../assets/navbar/SideNavBar/Enertalk.png');
  width: 205px;
  height: 55px;
  background-size: cover;
  background-position: center;
  margin-bottom: 20px;
}

.Authentication>.error-message {
  margin-top: 20px;
  font-size: 14px;
  color: #7b797b;
}
