.DrEventOverview {
  max-height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.DrEventOverview>.DrEventOverview__content {
  overflow: auto;
  margin-top: 10px;
}
