.Toolbar {
  display: flex;
  flex-direction: row;
  padding: 0 20px;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  min-height: 38px;
  user-select: none;
}

.Toolbar.buttons-only {
  justify-content: flex-end;
}

.Toolbar>.buttons {
  display: flex;
  align-items: center;
}

.Toolbar>.buttons>* {
  margin-right: 10px;
}

.Toolbar>.buttons>*:last-child {
  margin-right: 0;
}
