.DrEventUpload {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.DrEventUpload>.DrEventUpload__fileName {
  margin: 10px 0;
}

.DrEventUpload>.DrEventUpload__fileSelector {
  width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	z-index: -1;
}

.DrEventUpload>button {
  width: 50%;
  margin: 10px 0;
}